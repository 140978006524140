import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { useUserStore } from '@/store/user';

import FBImage from './img/fb.svg';
import IGImage from './img/ig.svg';
import LogoImage from './img/logo.png';
import MoneyImage from './img/money.svg';
import TGImage from './img/tg.svg';
import THDImage from './img/thd.svg';
import XImage from './img/x.svg';

import style from './infoFooter.module.scss';

function InfoFooter() {
    const router = useRouter();
    const isLogin = useUserStore.use.isLogin();
    const isCp = useUserStore.use.isCp();

    const handleEarnBtn = () => {
        if (isLogin && isCp) {
            router.push('/cpCreate/i');
        } else {
            window.open('https://whywhy.cc/bacp', '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <div className={style.infoFooter}>
            <div className={style.footer}>
                <Image src={LogoImage} width={108} height={24} alt="whywhy" />
                <p className={style.slogan}>解鎖你的大膽想法！</p>
                <div className={style.companyProfile}>
                    {false && (
                        <>
                            <p>富富科技股份有限公司</p>
                            <p>統編 94266122</p>
                        </>
                    )}

                    <p>
                        <span onClick={() => $crisp.push(['do', 'chat:open'])}>聯繫客服</span>
                    </p>
                </div>
                <div className={style.copyright}>© WHyWHy 保留一切權利</div>
                <div className={style.sns}>
                    <a href="https://why2.cc/officialfb" target="_blank">
                        <FBImage />
                    </a>
                    <a href="https://why2.cc/officialig" target="_blank">
                        <IGImage />
                    </a>
                    <a href="https://why2.cc/officialx" target="_blank">
                        <XImage />
                    </a>
                    <a href="https://why2.cc/officialtgchannel" target="_blank">
                        <TGImage />
                    </a>
                    <a href="https://why2.cc/officialthread" target="_blank">
                        <THDImage />
                    </a>
                </div>
                <div className={style.earn} onClick={handleEarnBtn}>
                    成為創作者 <MoneyImage />
                </div>
            </div>
        </div>
    );
}
export default InfoFooter;
