import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { getSearchCoupleList } from '@/api/couple/home';
import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import { useCpMediaStore } from '@/store/cpMedia';
import { SearchCpInfoType } from '@/types/home';

import GlassBoldIcon from './img/glassBold.svg';
import NoDataImage from './img/noData.png';
import RightIcon from './img/right.svg';

import style from './search.module.scss';

const highlightText = (targetText: string, searchValue: string) => {
    if (!searchValue.trim()) {
        return <span>{targetText}</span>;
    }
    const regex = new RegExp(`(${searchValue})`, 'i');
    const match = targetText.match(regex);

    if (match && match.index !== undefined) {
        const beforeMatch = targetText.slice(0, match.index);
        const matchedText = targetText.slice(match.index, match.index + searchValue.length);
        const afterMatch = targetText.slice(match.index + searchValue.length);

        return (
            <>
                {beforeMatch}
                <div className={style.highLine}>{matchedText}</div>
                {afterMatch}
            </>
        );
    }

    return <span>{targetText}</span>;
};

function ResultCpCard({ searchValue, cpInfo }: { searchValue: string; cpInfo: SearchCpInfoType }) {
    return (
        <div className={style.resultCpCard}>
            <CpAvatar src={cpInfo?.avatar} tag={cpInfo?.label?.tag || 'evil'} />
            <div className={style.info}>
                <div className={style.nickname}>{highlightText(cpInfo.nickname, searchValue)}</div>
                <div className={style.account}>@{highlightText(cpInfo.account, searchValue)}</div>
            </div>
        </div>
    );
}

function Search() {
    const openSearch = useCpMediaStore.use.openSearch();
    const setOpenSearch = useCpMediaStore.use.setOpenSearch();
    const [searchValue, setSearchValue] = useState('');
    const [cpList, setCpList] = useState<SearchCpInfoType[]>([]);
    const [resultList, setResultList] = useState<SearchCpInfoType[]>([]);
    const [isNoData, setIsNoData] = useState(false);
    const router = useRouter();

    const fetchCpList = async () => {
        const res = await getSearchCoupleList();
        if (res.status === 200) {
            setCpList(res.data);
        }
    };

    useEffect(() => {
        if (openSearch && cpList.length === 0) {
            fetchCpList();
        }
    }, [openSearch]);

    const handleSearch = (searchValue: string) => {
        setSearchValue(searchValue);

        if (searchValue.trim() === '') {
            setResultList([]);
            setIsNoData(false);
            return;
        }

        const filteredResults = cpList.filter(
            item =>
                item.nickname.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.account.toLowerCase().includes(searchValue.toLowerCase())
        );

        const uniqueResults = Array.from(new Set(filteredResults.map(item => item.account))).map(
            account => filteredResults.find(item => item.account === account)!
        );

        if (uniqueResults.length === 0) {
            setIsNoData(true);
        }

        setResultList(uniqueResults);
    };

    return (
        <FullScreenPopup
            isOpen={openSearch}
            close={() => setOpenSearch(false)}
            hiddenClose
            hiddenTop
        >
            <div className={style.searchPopup}>
                <div className={style.topBar}>
                    <div className={style.searchInput}>
                        <GlassBoldIcon />
                        <input
                            type="text"
                            value={searchValue}
                            onChange={event => handleSearch(event.target.value)}
                            placeholder={'請輸入暱稱或ID'}
                        />
                    </div>
                    <div className={style.closeBtn} onClick={() => setOpenSearch(false)}>
                        取消
                    </div>
                </div>
                <div className={style.resultContainer}>
                    {resultList.length > 0 && (
                        <div className={style.resultList}>
                            {resultList.map(result => (
                                <Link
                                    href={`/cp/${result.user_id}/i`}
                                    key={result.user_id}
                                    className={style.resultInfo}
                                    onClick={() => {
                                        router.push(`/cp/${result.user_id}/i`)
                                            .then(() => {
                                                setOpenSearch(false);
                                            });
                                    }}
                                >
                                    <ResultCpCard searchValue={searchValue} cpInfo={result} />

                                    <RightIcon />
                                </Link>
                            ))}
                        </div>
                    )}

                    {resultList.length === 0 && isNoData && (
                        <div className={style.noData}>
                            <Image
                                width={202}
                                height={146}
                                alt="no data"
                                src={NoDataImage}
                                priority
                            />
                            未找到相關的暱稱或id
                        </div>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    );
}

export default Search;
