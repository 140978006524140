import { MouseEvent } from 'react';

import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import { cpCreateImageListType } from '@/types/cpCreateType';
import {
    CpImageType,
    CpInfoType,
    CpPornAlbumEpisodeVideo,
    CpPornAlbumTrailerVideo,
    CpVideoType
} from '@/types/cpType';

import ChatIcon from '../img/chat.svg';
import FavoriteIcon from '../img/favorite.svg';
import ShareIcon from '../img/share.svg';
import SpeakerMutedIcon from '../img/speakerMuted.svg';
import SpeakerUnMutedIcon from '../img/speakerUnMuted.svg';
import SubscribeIcon from '../img/subscribe.svg';
import UnfavoriteIcon from '../img/unFavorite.svg';

import style from './videoSideMenu.module.scss';

type InfoType = CpVideoType | CpImageType | CpPornAlbumEpisodeVideo | CpPornAlbumTrailerVideo;
type CreateType = cpCreateImageListType;

type Props = {
    userInfo: CpInfoType;
    mediaData: InfoType | CreateType;
    hideView: boolean;
    handleLikeClick: (event: React.MouseEvent<HTMLElement>) => void;
    isLike: boolean;
    handleChat: (event: React.MouseEvent<HTMLElement>, type?: string) => void;
    handleShareBtn: (event: MouseEvent<HTMLElement>) => void;
    likeCount: number;
    toggleSound: (event: React.MouseEvent<HTMLElement>) => void;
    isFollow: boolean;
    isMuted: boolean;
    followingHandler: (event: React.MouseEvent<HTMLElement>) => void;
    handleToCpPage: () => void;
};

function VideoSideMenu({
    userInfo,
    mediaData,
    hideView,
    handleLikeClick,
    isLike,
    handleChat,
    handleShareBtn,
    likeCount,
    toggleSound,
    isFollow,
    isMuted,
    followingHandler,
    handleToCpPage
}: Props) {
    return (
        !hideView && (
            <div className={style.videoSideMenu}>
                <div className={style.avatarBtn}>
                    <CpAvatar
                        className={style.avatar}
                        src={userInfo.avatar}
                        tag={userInfo.label}
                        width={36}
                        height={36}
                        onClick={handleToCpPage}
                    />
                    {!isFollow && (
                        <SubscribeIcon className={style.subscribeIcon} onClick={followingHandler} />
                    )}
                </div>
                {typeof (mediaData as InfoType)?.is_favor !== 'undefined' && (
                    <div className={style.like}>
                        <div className={style.icon} onClick={handleLikeClick}>
                            {isLike ? <FavoriteIcon /> : <UnfavoriteIcon />}
                        </div>
                        <div className={style.likeCount}>{likeCount}</div>
                    </div>
                )}
                <div className={style.icon} onClick={event => handleChat(event)}>
                    <ChatIcon />
                </div>
                <div className={style.icon} onClick={event => handleShareBtn(event)}>
                    <ShareIcon />
                </div>
                <div className={style.icon} onClick={e => toggleSound(e)}>
                    {isMuted ? <SpeakerMutedIcon /> : <SpeakerUnMutedIcon />}
                </div>
            </div>
        )
    );
}

export default VideoSideMenu;
