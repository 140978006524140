import Link from 'next/link';

import { cpInfoType } from '@/api/couple/home';

import CpCoverCard from '../cpCoverCard/cpCoverCard';

import style from './cardList.module.scss';

function CardList({
    dataList,
    title,
    href,
    className
}: {
    dataList: cpInfoType[];
    title: string;
    href?: string;
    className?: string;
}) {
    if (dataList.length === 0) {
        return null;
    }

    return (
        <div className={`${style.cardList} ${className}`}>
            <div className={style.top}>
                <h6 className={style.title}>{title}</h6>
                <Link href={href || '/'} className={style.more}>
                    查看更多
                </Link>
            </div>

            <div className={style.mediaContent}>
                {dataList.map((data, idx) => (
                    <CpCoverCard cpInfo={data} key={idx} />
                ))}
            </div>
        </div>
    );
}

export default CardList;
