import Link from 'next/link';
import { useRef, useState } from 'react';

import { cpInfoType, getPopularList } from '@/api/couple/home';
import { GetBannerResponse } from '@/api/shop/shop';
import BaseBanner from '@/components/baseBanner/baseBanner';
import CpCoverCardList from '@/components/cp/cpCoverCardList/cpCoverCardList';
import { CurationListType } from '@/types/home';

import CardList from '../components/cardList/cardList';
import CurationList from '../components/curationList/curationList';

import style from './tagList.module.scss';

function AllCpList({ allCpList }: { allCpList: cpInfoType[] }) {
    const [cardList, setCardList] = useState<cpInfoType[]>(allCpList || []);
    const page = useRef(1);

    const handleFetchMoreList = async (page: number) => {
        const res = await getPopularList({ page, limit: 20 });
        if (res.status !== 200) return { isEnd: true };

        const newData = res.data.data;

        if (newData.length > 0) {
            setCardList(list => [...list, ...newData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    return (
        <div className={style.allCpList}>
            <div className={style.top}>
                <h6 className={style.title}>全部小壞壞</h6>
                <Link href="/curation/cpList/all" className={style.more}>
                    查看更多
                </Link>
            </div>
            <CpCoverCardList
                size="middle"
                cpList={cardList}
                page={page}
                handleFetchMoreList={handleFetchMoreList}
                className={style.cardContainer}
            />
        </div>
    );
}

type Props = {
    latestList: CurationListType;
    recommendList: CurationListType;
    bannerList: GetBannerResponse[];
    popularList: cpInfoType[];
    newbieList: cpInfoType[];
    allCpList: cpInfoType[];
};

function AllList({
    latestList,
    recommendList,
    bannerList,
    popularList,
    newbieList,
    allCpList
}: Props) {
    return (
        <div>
            <CurationList dataList={latestList} title={'最新動態'} href={'/curation/latest'} />
            <CurationList
                dataList={recommendList}
                title={'精選推薦'}
                href={'/curation/recommend'}
            />
            <BaseBanner bannerList={bannerList} />
            <CardList title="熱門小壞壞" dataList={popularList} href="/curation/cpList/popular" />
            <CardList title="新進小壞壞" dataList={newbieList} href="/curation/cpList/newbie" />
            <AllCpList allCpList={allCpList} />
        </div>
    );
}

export default AllList;
