import Image from 'next/image';
import Link from 'next/link';

import { cpInfoType } from '@/api/couple/home';

import AngelIcon from './img/angel.svg';
import EvilIcon from './img/evil.svg';

import style from './cpCoverCard.module.scss';

function CpCoverCard({
    cpInfo,
    size = 'small'
}: {
    cpInfo: cpInfoType;
    size?: 'small' | 'middle';
}) {
    const cpTypeIconMap = {
        evil: { icon: <EvilIcon /> },
        angel: { icon: <AngelIcon /> }
    };

    return (
        <Link className={style.cpImgCard} href={`/cp/${cpInfo.user_id}/i`}>
            <div className={`${style.cover} ${style[size]}`}>
                <div className={style.icon}>
                    {cpTypeIconMap[cpInfo.label.tag as 'evil' | 'angel'].icon}
                </div>
                <Image
                    alt=""
                    src={cpInfo.images[0]}
                    fill
                    sizes='140'
                    style={{ objectFit: 'cover' }}
                    className={`${style.img} ${style[size]}`}
                />
            </div>
            <div className={style.info}>
                <div className={style.nickname}>{cpInfo.nickname}</div>
                <div className={style.accountId}>@{cpInfo.account}</div>
            </div>
        </Link>
    );
}

export default CpCoverCard;
