import Cookies from 'js-cookie';
import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState, KeyboardEvent } from 'react';

import { sendEmailforLogin, loginWithMail, getUserInfoByToken } from '@/api/auth/auth';
import { getOwnCpInfo } from '@/api/couple/cpCreate';
import { fetchRegisterUtm } from '@/api/event/utm';
import BaseButton from '@/components/baseButton/baseButton';
import BaseInput from '@/components/baseInput/baseInput';
import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import SessionStorageManager from '@/lib/sessionStorageManager';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';

import style from './validateEmail.module.scss';
interface Props {
    isOpen: boolean;
    close: () => void;
    back: () => void;
    email: string;
}

function CountDownBtn({ email }: { email: string }) {
    const t = useTranslations();
    const [counter, setCounter] = useState(60);
    const [loading, setLoading] = useState(false);

    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (counter > 0) {
            timer.current = setTimeout(() => {
                setCounter(counter - 1);
            }, 1000);
        }
        return () => {
            if (timer.current) {
                clearInterval(timer.current);
            }
        };
    }, [counter]);

    const submit = async () => {
        if (counter > 0) return;
        setLoading(true);
        const res = await sendEmailforLogin(email);

        if (res.status === 200) {
            setCounter(60);
            setSnackMessage({
                message: t('mailVerify_sendCode_success')
            });
        } else {
            setSnackMessage({
                message: res.errorMsg
            });
        }

        setLoading(false);
    };

    return (
        <div className={style.countDownBtn}>
            <BaseButton
                className={`${style.countBtn} ${counter > 0 ? style.disabled : ''}`}
                disabled={counter > 0}
                onClick={submit}
                loading={loading}
            >
                {counter > 0 ? `${counter}s` : t('mailVerify_sendCode_button')}
            </BaseButton>
        </div>
    );
}

function ValidateEmail({ close, back, isOpen, email }: Props) {
    const t = useTranslations();
    const [validateCode, setValidateCode] = useState('');
    const [loading, setLoading] = useState(false);
    const setSnackMessage = useNotifyStore.use.setSnackMessage();
    const setInitUserInfo = useUserStore.use.setInitUserInfo();
    const setInitCpInfo = useUserStore.use.setInitCpInfo();
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const login = async () => {
        if (validateCode.length !== 6) {
            setSnackMessage({
                message: t('mailVerify_input_placeholder')
            });
            setEmailError(t('mailVerify_input_placeholder'));
            return;
        }
        setEmailError('');
        setLoading(true);
        const res = await loginWithMail({ email, validateCode });

        if (res.status === 200) {
            Cookies.set('access', res.data.access_token, { expires: 30 });
            Cookies.set('refresh', res.data.refresh_token, { expires: 30 });
            const userInfo = await getUserInfoByToken(res.data.access_token);
            if (userInfo.status === 200) {
                if (res.data.is_new) {
                    if (SessionStorageManager.get('utm_config')) {
                        const utmParams = JSON.parse(
                            SessionStorageManager.get('utm_config') as string
                        );
                        const params = {
                            ...{ user_id: userInfo.data.user_id },
                            ...utmParams
                        };
                        window.gtag('event', 'register', params);

                        fetchRegisterUtm(utmParams);

                        SessionStorageManager.remove('utm_config');
                    } else {
                        window.gtag('event', 'register', { user_id: userInfo.data.user_id });
                    }
                }
                setInitUserInfo(userInfo.data);
                setSnackMessage({
                    message: t('mailVerify_login_success')
                });
                close();
                await getCpInfo();
            } else {
                setSnackMessage({
                    message: t('mailVerify_login_failed')
                });
            }
        } else {
            setSnackMessage({
                message: res.errorMsg
            });
        }
        setLoading(false);
    };

    const getCpInfo = async () => {
        const res = await getOwnCpInfo();

        if (res.status === 200) {
            setInitCpInfo(res.data);
            window.location.href = '/cpCreate/i';
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            login();
        }
    };

    return (
        <FullScreenPopup isOpen={isOpen} close={close} back={back} zIndex={1201}>
            <div className={style.validateEmail}>
                <h2 className={style.title}>{t('mailVerify_title_firstText')}</h2>
                <div className={style.subTip}>{t('mailVerify_title_secondText')}</div>
                <h3 className={style.label}>{t('mailVerify_input_label')}</h3>
                <div className={style.validateCode}>
                    <BaseInput
                        className={style.input}
                        type="text"
                        value={validateCode}
                        onChange={val => setValidateCode(val)}
                        placeholder={t('mailVerify_input_placeholder')}
                        onKeyDown={handleKeyDown}
                        errorMessage={emailError}
                    />
                    <CountDownBtn email={email} />
                </div>
                <BaseButton className={style.submit} onClick={login} loading={loading}>
                    {t('mailVerify_verify_button')}
                </BaseButton>
            </div>
        </FullScreenPopup>
    );
}

export default ValidateEmail;
