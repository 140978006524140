import CircularProgress from '@mui/material/CircularProgress';
import { useState, useRef, useEffect } from 'react';

import BaseButton from '@/components/baseButton/baseButton';
import { InfiniteScroll } from '@/components/infiniteScroll/infiniteScroll';
import SessionStorageManager from '@/lib/sessionStorageManager';
import {
    ExploreListType,
    ExploreInfoType,
    ExploreCardType,
    ExplorePostImageType,
    ExplorePostVideoType,
    CurationListType,
    CurationType
} from '@/types/home';

import PostCard from '../postCard/postCard';
import ProfileCard from '../profileCard/profileCard';

import style from './exploreList.module.scss';

function ExploreList({
    title,
    dataList,
    showFollowBtn,
    loadMoreList,
    defaultPage,
    thirdSlot,
    className
}: {
    title?: string;
    dataList: ExploreListType | CurationListType;
    showFollowBtn?: boolean;
    loadMoreList: (page: number) => Promise<{ isEnd: boolean }>;
    defaultPage?: number;
    thirdSlot?: React.ReactNode;
    className?: string;
}) {
    const page = useRef(defaultPage || 1);
    const isLoading = useRef(false);
    const [isEnd, setIsEnd] = useState(false);
    const [hideMoreBtn, setHideMoreBtn] = useState(false);
    const handleloadMore = async () => {
        if (isLoading.current) return;

        isLoading.current = true;
        page.current += 1;
        const res = await loadMoreList(page.current);
        if (res.isEnd) {
            setIsEnd(res.isEnd);
        }

        isLoading.current = false;
    };

    useEffect(() => {
        setHideMoreBtn(SessionStorageManager.get('isShowMore') ? true : false);
    }, []);

    const handleClickMore = () => {
        setHideMoreBtn(true);
        SessionStorageManager.set('isShowMore', 'true');
    };

    return (
        <div className={`${style.exploreList} ${className}`}>
            {title && <div className={style.title}>{title}</div>}
            {dataList.map((cardInfo: ExploreInfoType | CurationType, idx) => (
                <div key={`${cardInfo?.account}_${cardInfo?.userId}_${idx}`}>
                    {cardInfo?.feedType === 'cp_card' ? (
                        <ProfileCard cpPopular={cardInfo as ExploreCardType} />
                    ) : (
                        <PostCard
                            postData={cardInfo as ExplorePostImageType | ExplorePostVideoType}
                            showFollowBtn={showFollowBtn}
                        />
                    )}
                    {idx === 2 && thirdSlot && <>{thirdSlot}</>}
                </div>
            ))}

            {dataList.length > 0 && (
                <>
                    {hideMoreBtn ? (
                        <>
                            {isEnd ? (
                                <div className={style.listEnd}>
                                    {dataList.length > 0 && '已經到底嘍…'}
                                </div>
                            ) : (
                                <InfiniteScroll onVisible={handleloadMore}>
                                    <div className={style.loadMore}>
                                        <CircularProgress size={24} sx={{ color: '#e52027' }} />
                                    </div>
                                </InfiniteScroll>
                            )}
                        </>
                    ) : (
                        <BaseButton
                            type={'normal'}
                            className={style.moreBtn}
                            onClick={() => handleClickMore()}
                        >
                            載入更多
                        </BaseButton>
                    )}
                </>
            )}
        </div>
    );
}

export default ExploreList;
