import { fetcher } from '@/lib/fetcher';
import { CpTagType } from '@/types/cpType';
import { ExploreListType, CurationListType, SearchCpInfoType } from '@/types/home';

export interface cpInfoType {
    account: string;
    avatar: string;
    images: string[];
    label: {
        background: string;
        icon: string;
        name: string;
        opacity: number;
        tag: CpTagType;
        text: string;
    };
    nickname: string;
    tags: string[];
    user_id: string;
}

export const getPopularList = ({
    page,
    serverAccess,
    limit = 10
}: {
    page: number;
    serverAccess?: string;
    limit?: number;
}) => {
    return fetcher<{ data: cpInfoType[] }, { page: number; limit: number }>(
        {
            url: '/couple/v1/popular-list',
            method: 'GET',
            data: {
                limit: limit,
                page
            }
        },
        {},
        serverAccess
    );
};

export const getNewbieList = ({
    page,
    serverAccess,
    limit = 10
}: {
    page: number;
    serverAccess?: string;
    limit?: number;
}) => {
    return fetcher<{ data: cpInfoType[] }, { page: number; limit: number }>(
        {
            url: '/couple/v1/newbie-list',
            method: 'GET',
            data: {
                limit: limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// [探索]動態清單
export const getExploreList = ({
    page,
    type,
    serverAccess
}: {
    page: number;
    type: string;
    serverAccess?: string;
}) => {
    const limit = 16;
    return fetcher<
        ExploreListType,
        { page: number; limit: number; type: string; serverAccess?: string }
    >(
        {
            url: '/couple/v2/explore/feeds',
            method: 'GET',
            data: {
                page,
                limit,
                type
            }
        },
        {},
        serverAccess
    );
};

// 取得最新動態
export const getLatestList = ({
    page,
    limit,
    mode,
    serverAccess
}: {
    page: number;
    limit: number;
    mode?: string;
    serverAccess?: string;
}) => {
    return fetcher<
        CurationListType,
        { page: number; limit: number; mode?: string; serverAccess?: string }
    >(
        {
            url: '/couple/v2/curation/latest',
            method: 'GET',
            data: {
                mode: mode || 'curation',
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 取得精選推薦
export const getRecommendList = ({
    page,
    limit,
    mode,
    serverAccess
}: {
    page: number;
    limit: number;
    mode?: string;
    serverAccess?: string;
}) => {
    return fetcher<
        CurationListType,
        { page: number; limit: number; mode?: string; serverAccess?: string }
    >(
        {
            url: '/couple/v2/curation/recommend',
            method: 'GET',
            data: {
                mode: mode || 'curation',
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 取得新人推薦
export const getRisingstarsList = ({
    page,
    limit,
    mode,
    serverAccess
}: {
    page: number;
    limit: number;
    mode?: string;
    serverAccess?: string;
}) => {
    return fetcher<CurationListType, { page: number; limit: number; mode: string }>(
        {
            url: '/couple/v2/curation/risingstars',
            method: 'GET',
            data: {
                mode: mode || 'curation',
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 取得熱門主題
export const getTrendingList = ({
    page,
    limit,
    mode,
    serverAccess
}: {
    page: number;
    limit: number;
    mode?: string;
    serverAccess?: string;
}) => {
    return fetcher<CurationListType, { page: number; limit: number; mode: string }>(
        {
            url: '/couple/v2/curation/trending',
            method: 'GET',
            data: {
                mode: mode || 'curation',
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 取得我的小壞壞
export const getMyrelevantList = ({
    page,
    limit,
    mode,
    serverAccess
}: {
    page: number;
    limit: number;
    mode?: string;
    serverAccess?: string;
}) => {
    return fetcher<CurationListType, { page: number; limit: number; mode: string }>(
        {
            url: '/couple/v2/curation/myrelevant',
            method: 'GET',
            data: {
                mode: mode || 'curation',
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};

// 獲取 search bar cp 清單
export const getSearchCoupleList = () => {
    return fetcher<SearchCpInfoType[], null>({
        url: '/couple/v1/couple-list',
        method: 'GET'
    });
};

// 取得我關注中的最新上線的CP列表
export const getMyrRecentlyOnline = ({
    page,
    limit,
    serverAccess
}: {
    page: number;
    limit: number;
    serverAccess?: string;
}) => {
    return fetcher<cpInfoType[], { page: number; limit: number; }>(
        {
            url: '/couple/v1/followee/recent-login',
            method: 'GET',
            data: {
                limit,
                page
            }
        },
        {},
        serverAccess
    );
};
