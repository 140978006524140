'use client';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { uploadFileToS3 } from '@/api/common/common';
import { updateUserInfo, getUploadUserAvatarUrl } from '@/api/user/user';
import BaseButton from '@/components/baseButton/baseButton';
import BaseDatePicker from '@/components/baseDatePicker/baseDatePicker';
import BaseInput from '@/components/baseInput/baseInput';
import FileUploader from '@/components/fileUploader/FileUploader';
import FullScreenPopup from '@/components/fullScreenPopup/FullScreenPopup';
import { useNotifyStore } from '@/store/notify';
import { useUserStore } from '@/store/user';
import { isAgeValid } from '@/utils/timeConverter';

import EditAvatar from './editAvatar';
import IconBanana from './img/banana.svg';
import DefaultAvatar from './img/defaultAvatar.png';
import IconPeach from './img/peach.svg';

import style from './userInfoCompleted.module.scss';

export function useUserInfoCompletedHook() {
    const setUserInfoCompletedModalVisible = useNotifyStore.use.setUserInfoCompletedModalVisible();
    const userInfo = useUserStore.use.userInfo();

    const checkUserInfoCompleted = () => {
        if (!userInfo.is_completed) {
            setUserInfoCompletedModalVisible(true);
            return true;
        }
        return false;
    };

    return {
        checkUserInfoCompleted
    };
}

function UserInfoCompleted() {
    const router = useRouter();
    const t = useTranslations();
    const isLogin = useUserStore.use.isLogin();
    const userInfo = useUserStore.use.userInfo();
    const updateUserInfoStore = useUserStore.use.updateUserInfo();

    const setUserInfoCompletedModalVisible = useNotifyStore.use.setUserInfoCompletedModalVisible();
    const userInfoCompletedModalVisible = useNotifyStore.use.userInfoCompletedModalVisible();

    const [nickname, setNickname] = useState(userInfo.nickname || '');
    const [birthday, setBirthday] = useState(userInfo.birthday || '');
    const [avatar, setAvatar] = useState(userInfo.avatar || '');
    const [avatarFile, setAvatarFile] = useState<File>();
    const [gender, setGender] = useState<1 | 2>(userInfo.gender);
    const setSnackMessage = useNotifyStore.use.setSnackMessage();

    useEffect(() => {
        isLogin && setUserInfoCompletedModalVisible(!userInfo.is_completed);
    }, [isLogin, setUserInfoCompletedModalVisible, userInfo.is_completed]);

    const updateUserInfoHandler = async () => {
        if (disabled) return;
        let avatarUrl = avatar;
        if (avatarFile) {
            const urlResponse = await getUploadUserAvatarUrl();
            if (urlResponse.status === 200 && avatarFile) {
                await uploadFileToS3(avatarFile, urlResponse.data.upload);
                avatarUrl = urlResponse.data.play_url;
            } else {
                setSnackMessage({ message: t('globalMessage_upload_failed') });
                return;
            }
        }
        const res = await updateUserInfo({
            avatar: avatarUrl,
            nickname,
            description: userInfo.description,
            gender: gender,
            birthday
        });

        if (res.status === 200) {
            setSnackMessage({ message: t('globalMessage_save_success') });
            updateUserInfoStore({
                avatar: avatarUrl,
                nickname,
                description: userInfo.description,
                gender: gender,
                birthday,
                is_completed: true
            });
            setAvatarFile(undefined);
            setUserInfoCompletedModalVisible(false);
            router.push('/');
            return;
        }
        setSnackMessage({ message: t('globalMessage_save_failed') });
    };

    const onFileLoadedHandler = (imageData: string | null, file?: File | undefined) => {
        if (imageData && file) {
            setAvatar(URL.createObjectURL(file));
            setAvatarFile(file);
        }
    };

    const back = () => {
        setUserInfoCompletedModalVisible(false);
    };

    const disabled = !nickname || !birthday || !isAgeValid(birthday);

    return (
        <FullScreenPopup
            isOpen={userInfoCompletedModalVisible}
            back={back}
            close={() => setUserInfoCompletedModalVisible(false)}
            zIndex={1201}
        >
            <div className={style.userInfoCompletedContainer}>
                <div className={style.userInfoCompleted}>
                    <div className={style.titleArea}>
                        <h2 className={style.title}>{t('userInfoPopup_welcome_text')}</h2>
                        <h3 className={style.subTitle}>{t('userInfoPopup_welcome_subtext')}</h3>
                    </div>
                    <FileUploader onFileLoaded={onFileLoadedHandler}>
                        <EditAvatar src={avatar || DefaultAvatar} />
                    </FileUploader>
                    <div className={style.formContainer}>
                        <BaseInput
                            label={`${t('userInfoPopup_nickInput_label')}*`}
                            placeholder={t('userInfoPopup_nickInput_placeholder')}
                            value={nickname}
                            onChange={value => setNickname(value)}
                        />
                        <div className={style.birthday}>
                            <BaseDatePicker
                                label={`${t('userInfoPopup_birthdayInput_label')}*`}
                                date={birthday}
                                onChange={value => setBirthday(value)}
                                max={new Date().toISOString().split('T')[0]}
                            />
                            <h4
                                className={`${style.birthdayTips} ${isAgeValid(birthday) || !birthday ? '' : style.birthdayTipsAdult
                                    }`}
                            >
                                {t('userInfoPopup_birthdayInput_subtext')}
                            </h4>
                        </div>
                        <div className={style.gender}>
                            <div className={style.label}>
                                {t('userInfoPopup_genderInput_label')}*
                            </div>
                            <div className={style.genderOptions}>
                                <div
                                    className={`${style.genderOption} ${gender === 1 ? style.selectOption : ''
                                        }`}
                                    onClick={() => setGender(1)}
                                >
                                    <IconBanana />
                                    <div className={style.genderText}>
                                        {t('userInfoPopup_male_button')}
                                    </div>
                                </div>
                                <div
                                    className={`${style.genderOption} ${gender === 2 ? style.selectOption : ''
                                        }`}
                                    onClick={() => setGender(2)}
                                >
                                    <IconPeach />
                                    <div className={style.genderText}>
                                        {t('userInfoPopup_female_button')}
                                    </div>
                                </div>
                            </div>
                            <div className={style.genderTips}>
                                {t('userInfoPopup_genderInput_subtext')}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className={style.footer}>
                    <BaseButton disabled={disabled} onClick={updateUserInfoHandler}>
                        {t('userInfoPopup_start_button')}
                    </BaseButton>
                </footer>
            </div>
        </FullScreenPopup>
    );
}

export default UserInfoCompleted;
