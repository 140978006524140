import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.17.0_next@14.2.1_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0__react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next@14.2.1_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/base/clientBaseConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/base/utmSetting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/notify/confirmModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/notify/fcmModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/notify/messageModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/notify/snackMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/uploadProgress/uploadProgress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layout)/user/clientUserInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(store)/storeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/chat/chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/crisp/crisp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/depositModal/depositModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/detailPopup/DetailPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/login/login.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/notification/notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/pwaTip/pwaTip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/teenagerWarning/teenagerWarning.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/userInfoCompleted/userInfoCompleted.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/validatePhone/validatePhone.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/webSocket/webSocket.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/reset.css");
