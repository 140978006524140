import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { cpInfoType } from '@/api/couple/home';
import CpCoverCard from '@/app/(home)/components/cpCoverCard/cpCoverCard';
import BaseButton from '@/components/baseButton/baseButton';
import { InfiniteScroll } from '@/components/infiniteScroll/infiniteScroll';
import SessionStorageManager from '@/lib/sessionStorageManager';

import style from './cpCoverCardList.module.scss';

type Props = {
    cpList: cpInfoType[];
    handleFetchMoreList: (page: number) => Promise<{ isEnd: boolean }>;
    page: {
        current: number;
    };
    size?: 'small' | 'middle';
    className?: string;
};

function CpCoverCardList({ cpList, handleFetchMoreList, page, size = 'small', className }: Props) {
    const isLoading = useRef(false);
    const [isEnd, setIsEnd] = useState(false);
    const [hideMoreBtn, setHideMoreBtn] = useState(false);
    const handleloadMore = async () => {
        if (isLoading.current) return;

        isLoading.current = true;
        page.current += 1;
        const res = await handleFetchMoreList(page.current);
        if (res.isEnd) {
            setIsEnd(res.isEnd);
        }

        isLoading.current = false;
    };

    useEffect(() => {
        setHideMoreBtn(SessionStorageManager.get('isShowMore') ? true : false);
    }, []);

    const handleClickMore = () => {
        setHideMoreBtn(true);
        SessionStorageManager.set('isShowMore', 'true');
    };

    return (
        <div className={`${style.cpCoverCardList} ${className}`}>
            <div className={style.cpCoverCardContainer}>
                {cpList.map((cardInfo, idx) => (
                    <CpCoverCard key={`${cardInfo?.account}_${idx}`} cpInfo={cardInfo} size={size} />
                ))}
            </div>
            {cpList.length > 0 && (
                <>
                    {hideMoreBtn ? (
                        <>
                            {isEnd ? (
                                <div className={style.listEnd}>
                                    {cpList.length > 0 && '已經到底嘍…'}
                                </div>
                            ) : (
                                <InfiniteScroll onVisible={handleloadMore}>
                                    <div className={style.loadMore}>
                                        <CircularProgress size={24} sx={{ color: '#e52027' }} />
                                    </div>
                                </InfiniteScroll>
                            )}
                        </>
                    ) : (
                        <BaseButton
                            type={'normal'}
                            className={style.moreBtn}
                            onClick={() => handleClickMore()}
                        >
                            載入更多
                        </BaseButton>
                    )}
                </>
            )}
        </div>
    );
}

export default CpCoverCardList;
