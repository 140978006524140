'use client';
import { cpInfoType } from '@/api/couple/home';
import NoData from '@/app/(home)/components/noData/noData';
import { CurationListType, ExploreListType } from '@/types/home';

import CardList from '../components/cardList/cardList';
import CurationList from '../components/curationList/curationList';
import ExploreList from '../components/exploreList/exploreList';

import style from './tagList.module.scss';

function FollowList({
    myrelevantList,
    exploreList,
    loadMoreList,
    myRecentlyOnlineList
}: {
    myrelevantList: CurationListType;
    exploreList: ExploreListType | CurationListType;
    loadMoreList: (page: number) => Promise<{ isEnd: boolean }>;
    myRecentlyOnlineList: cpInfoType[];
}) {
    if (myrelevantList.length === 0) {
        return <NoData title={'你沒有任何正在關注中的小壞壞喔'} />;
    }

    return (
        <div className={style.tagContainer}>
            <CardList
                title="最新上線"
                dataList={myRecentlyOnlineList}
                href="/curation/cpList/myRecentlyOnline"
            />
            <CurationList
                dataList={myrelevantList}
                title="最新動態"
                href={'/curation/myrelevant'}
            />
            <ExploreList dataList={exploreList} loadMoreList={loadMoreList} defaultPage={1} />
        </div>
    );
}

export default FollowList;
