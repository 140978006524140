import {
    cpCreateImageListType,
    CpPornAlbumInfo as CpCreatePornAlbumInfo
} from '@/types/cpCreateType';
import {
    CpImageType,
    CpInfoType,
    CpPornAlbumEpisodeVideo,
    CpPornAlbumInfo,
    CpPornAlbumTrailerVideo,
    CpVideoType
} from '@/types/cpType';

import DiamondIcon from '../img/diamond.svg';
import UnLockIcon from '../img/unLock.svg';
import ViewIcon from '../img/view.svg';

import style from './videoCpInfoFooter.module.scss';

type InfoType = CpVideoType | CpImageType | CpPornAlbumEpisodeVideo | CpPornAlbumTrailerVideo;
type CreateType = cpCreateImageListType;

type Props = {
    userInfo: CpInfoType;
    mediaData: InfoType | CreateType;
    isPorn: boolean;
    isTrailer: boolean;
    albumInfo?: CpPornAlbumInfo | CpCreatePornAlbumInfo;
    handleToCpPage: () => void;
    isFollow: boolean;
    followingHandler: (event: React.MouseEvent<HTMLElement>) => void;
    hideView: boolean;
};

function VideoCpInfoFooter({
    userInfo,
    mediaData,
    isPorn,
    isTrailer,
    albumInfo,
    handleToCpPage,
    isFollow,
    followingHandler,
    hideView
}: Props) {

    return (
        !hideView && (
            <div className={style.videoCpInfoFooter}>
                <div className={style.nameInfo}>
                    <div className={style.name}>
                        <div className={style.nickname} onClick={handleToCpPage}>
                            {userInfo.nickname}
                        </div>
                        {!isFollow && <div className={style.subscribe} onClick={followingHandler}>
                            關注
                        </div>}
                    </div>
                    <div className={style.userId}>@{userInfo.user_id}</div>
                </div>
                {(mediaData as CpVideoType | CpImageType)?.description?.length > 0 && (
                    <div className={style.content}>
                        {(mediaData as CpVideoType | CpImageType)?.description || ''}
                    </div>
                )}
                <div className={style.tags}>
                    {userInfo.tags.map((tag, idx) => (
                        <div className={style.tag} key={`${tag}_${idx}`}>
                            #{tag}
                        </div>
                    ))}
                </div>
                <div className={style.mediaInfo}>
                    <div className={style.info}>
                        <ViewIcon />
                        {mediaData.view_count || 0}
                    </div>
                    {isPorn ? (
                        <>
                            {!isTrailer && !(mediaData as CpPornAlbumEpisodeVideo).is_public && (
                                <>
                                    <div className={style.info}>
                                        <DiamondIcon /> {albumInfo?.diamond}鑽
                                    </div>
                                    <div className={style.info}>
                                        <UnLockIcon /> {mediaData.unlock_count}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {(mediaData as CpVideoType | CpImageType).diamond > 0 && (
                                <>
                                    <div className={style.info}>
                                        <DiamondIcon />{' '}
                                        {albumInfo?.diamond ||
                                            (mediaData as CpVideoType | CpImageType).diamond}
                                        鑽
                                    </div>
                                    <div className={style.info}>
                                        <UnLockIcon /> {mediaData.unlock_count}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    );
}

export default VideoCpInfoFooter;
