'use client';

import { useState, useEffect } from 'react';

import { getExploreList, getMyrelevantList, cpInfoType, getMyrRecentlyOnline } from '@/api/couple/home';
import { getBanner } from '@/api/shop/shop';
import { GetBannerResponse } from '@/api/shop/shop';
import BaseTabs from '@/components/baseTabs/baseTabs';
import { useCpMediaStore } from '@/store/cpMedia';
import { useUserStore } from '@/store/user';
import { ExploreListType, CurationListType } from '@/types/home';

import InfoFooter from './components/infoFooter/infoFooter';
import GlassBoldIcon from './img/glassBold.svg';
import AllList from './tagList/allList';
import FollowList from './tagList/followList';

import style from './home.module.scss';

function HomePage({
    initLatestList,
    initRecommendList,
    initPopularList,
    initNewbieList,
    initAllCpList
}: {
    initLatestList: CurationListType;
    initRecommendList: CurationListType;
    initPopularList: cpInfoType[];
    initNewbieList: cpInfoType[];
    initAllCpList: cpInfoType[];
}) {
    const tabOption = [
        { label: '全部', value: 0 },
        { label: '關注', value: 1 }
    ];
    const [tabValue, setTabValue] = useState(0);
    const [bannerList, setBannerList] = useState<GetBannerResponse[]>([]);
    const setOpenSearch = useCpMediaStore.use.setOpenSearch();
    const isLogin = useUserStore.use.isLogin();

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    const [myrelevantList, setMyrelevantList] = useState<CurationListType>([]);
    const [myrelevantExploreList, setMyrelevantExploreList] = useState<ExploreListType>([]);
    const [myRecentlyOnlineList, setMyRecentlyOnlineList] = useState<cpInfoType[]>([]);

    const fetchMyrelevantList = async (page: number, mode: string) => {
        const res = await getMyrelevantList({ page, limit: 20, mode });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setMyrelevantList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const fetchMyrelevantsExploreList = async (page: number) => {
        const res = await getExploreList({ page, type: 'follow' });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setMyrelevantExploreList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const fetchMyRecentlyOnlineList = async (page: number) => {
        const res = await getMyrRecentlyOnline({
            page,
            limit: 20
        });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const recentlyOnlineData = res.data;
        if (recentlyOnlineData.length > 0) {
            setMyRecentlyOnlineList(list => [...list, ...recentlyOnlineData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    useEffect(() => {
        if (isMounted && tabValue === 1 && isLogin) {
            fetchMyrelevantList(1, 'curation');
            fetchMyrelevantsExploreList(1);
            fetchMyRecentlyOnlineList(1);
        }
    }, [tabValue, isMounted, isLogin]);

    useEffect(() => {
        if (isMounted) {
            fetchBanner();
        }
    }, [isMounted]);

    const fetchBanner = async () => {
        const localeList = ['tw', 'cn', 'en', 'es', 'pt', 'vn'];
        const subdomain = location.host.split('.')[0] || 'www';
        const countryCode = localeList.includes(subdomain) ? subdomain : '';

        const res = await getBanner(countryCode);

        if (res.status === 200) {
            setBannerList(res.data.result);
        }
    };

    return (
        <div className={style.homePage}>
            <div className={style.homeHeader}>
                <BaseTabs
                    className={style.tabBar}
                    options={tabOption}
                    value={tabValue}
                    onChange={value => setTabValue(value as number)}
                />
                <div className={style.searchInput} onClick={() => setOpenSearch(true)}>
                    <GlassBoldIcon />
                    <input type="text" placeholder={'請輸入暱稱或ID'} onFocus={(e) => e.target.blur()} />
                </div>
            </div>

            <div className={style.homeContent}>
                {tabValue === 0 && (
                    <>
                        <AllList
                            latestList={initLatestList}
                            recommendList={initRecommendList}
                            bannerList={bannerList}
                            popularList={initPopularList}
                            newbieList={initNewbieList}
                            allCpList={initAllCpList}
                        />
                    </>
                )}

                {tabValue === 1 && (
                    <FollowList
                        myrelevantList={myrelevantList}
                        exploreList={myrelevantExploreList}
                        loadMoreList={fetchMyrelevantsExploreList}
                        myRecentlyOnlineList={myRecentlyOnlineList}
                    />
                )}
            </div>
            <InfoFooter />
        </div>
    );
}

export default HomePage;
